import { keyframes } from 'styled-components';

export const backgroundFade = keyframes`
  from {
    background-color: rgba(50, 50, 50, 0);
  }
  to{
    background-color: rgba(50, 50, 50, 0.9);
  }
`;

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 12%);
  }
  to{
    opacity: 1;
    transform: translate(0, 0%);
  }
`;
