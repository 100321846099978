import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiLock, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import AuthError from '../../errors/AuthError';

import { useTheme } from '../../hooks/Theme';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import Button from '../../components/Button';

import getValidationErrors from '../../utils/getValidationErrors';

import vizuuLogo from '../../assets/vizuu_logo.png';

import { Container, LoginCard, VizuuLogo } from './styles';

interface LoginFormData {
  // email: string;
  password: string;
}

const Login: React.FC = () => {
  const { getCompanyTheme, companyName, backgroundURL, logoUrl } = useTheme();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (companyName.length) return;
    getCompanyTheme();
  }, [companyName, getCompanyTheme]);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: LoginFormData) => {
      try {
        const schema = Yup.object().shape({
          // email: Yup.string()
          //   .required('Email obrigatório')
          //   .email('Email inválido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { validLogin } = await signIn(data);

        if (!validLogin) {
          addToast({
            type: 'error',
            title: 'Credenciais Inválidas',
            description: 'Senha Incorreta',
          });

          throw new AuthError([
            { path: 'email', message: 'Credenciais Inválidas' },
            { path: 'password', message: 'Credenciais Inválidas' },
          ]);
        }
      } catch (err) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    },
    [addToast, signIn],
  );

  return (
    <Container background={backgroundURL}>
      <LoginCard>
        <img src={logoUrl} alt={companyName} />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça seu Login</h1>

          {/* <Input name="email" type="text" icon={FiMail} placeholder="Email" /> */}

          <Input
            name="password"
            type="password"
            icon={FiLock}
            placeholder="Senha"
          />

          <Button type="submit">Entrar</Button>

          <Link to="forgot-password">Esqueci minha senha</Link>
        </Form>

        <VizuuLogo>
          <span>Powered by:</span>
          <img src={vizuuLogo} alt="Vizuu" />
        </VizuuLogo>
      </LoginCard>
    </Container>
  );
};

export default Login;
