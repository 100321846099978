import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
// import HashLogin from '../pages/HashLogin';
import PasswordForgot from '../pages/PasswordForgot';
import Dashboard from '../pages/Dashboard';
import Contact from '../pages/Contact';
import RoomSelector from '../pages/RoomSelector';
import RoomEditor from '../pages/RoomEditor';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/forgot-password" component={PasswordForgot} />
      <Route path="/home" component={Dashboard} isPrivate />
      <Route path="/contact" component={Contact} isPrivate />
      <Route path="/edit" component={RoomSelector} exact isPrivate />
      <Route path="/edit/:selectedRoom" component={RoomEditor} isPrivate />
      {/* <Route path="/:userHash" component={HashLogin} /> */}
    </Switch>
  );
};

export default Routes;
