import React, { useState, useCallback, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { FiArrowLeft, FiXSquare } from 'react-icons/fi';

import Header from '../../components/Header';
import EditTabs from '../../components/EditTabs';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import { useTheme } from '../../hooks/Theme';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import {
  Container,
  Content,
  SideMenu,
  ItemsList,
  Conclusion,
  ItemConfig,
  SelectablesList,
  Selectable,
  ModeSelector,
  RenderEffects,
  InfoCard,
  ConfirmationModal,
  ConfirmationCard,
  ConfirmationList,
  ConfirmationItem,
  ConfirmationButtons,
  LoadingBar,
} from './styles';

type Modes = 'item' | 'model' | 'material' | 'confirmation';

interface Model {
  id: string;
  name: string;
  thumb: string;
}

interface Material {
  id: string;
  name: string;
  thumb: string;
}

interface Customizer extends Model {
  father: string;
  selected: boolean;
  type: Modes;
}

interface Item {
  id: string;
  name: string;
  icon: string;
  model_list: Model[];
  material_list: Material[];
  selected_model: string;
  selected_material: string;
}

interface APIReponse {
  name: string;
  status: boolean;
  itemLib: Item[];
}

interface InfoCardProps {
  active: boolean;
  itemName: string;
  itemThumb: string;
}

interface SelectedItem {
  type: string;
  model: string | undefined;
  material: string | undefined;
}

const RoomEditor: React.FC = () => {
  const [roomName, setRoomName] = useState('');
  const [mode, setMode] = useState<Modes>('item');
  const [activeItem, setActiveItem] = useState<Item | null>(null);

  const [selectables, setSelectables] = useState<Customizer[] | null>(null);

  const [apartmentCompleted, setApartmentCompleted] = useState(false);

  const [bgImage, setBgImage] = useState('');
  const [renderLoading, setRenderLoading] = useState(true);

  const [allSelectedItens, setAllSelectedItems] = useState<SelectedItem[]>([]);

  const [itemLib, setItemLib] = useState<Item[]>([]);

  const [infoCard, setInfoCard] = useState<InfoCardProps>({
    active: false,
    itemName: '',
    itemThumb: '',
  });

  const { params } = useRouteMatch<{ selectedRoom: string }>();
  const { addToast } = useToast();
  const { color } = useTheme();
  const history = useHistory();

  useEffect(() => {
    if (!activeItem) return;
    const partial =
      mode === 'model' ? activeItem.model_list : activeItem.material_list;

    const selectedItem =
      mode === 'model'
        ? activeItem.selected_model
        : activeItem.selected_material;

    const loadPromises = partial.map((selectable) => {
      const newImage = new Image();
      newImage.src = selectable.thumb;

      return new Promise((resolve, reject) => {
        newImage.onload = resolve;
      });
    });

    const newSelectables = partial.map((selectable) => {
      return {
        ...selectable,
        selected: selectable.id === selectedItem,
        type: mode,
        father: activeItem.id,
      };
    });

    Promise.all(loadPromises).then(() => setSelectables(newSelectables));
  }, [activeItem, mode]);

  const resetInfoCard = useCallback(() => {
    setInfoCard({
      active: false,
      itemName: '',
      itemThumb: '',
    });
  }, []);

  const handleItemSelection = useCallback(
    (itemId: string): void => {
      const findItem = itemLib.find((item) => item.id === itemId);
      if (!findItem) return;

      const newMode = findItem.model_list.length < 2 ? 'material' : 'model';

      setMode(newMode);
      setActiveItem(findItem);
      setSelectables(null);
    },
    [itemLib],
  );

  const handleGoBack = useCallback(() => {
    if (mode === 'item') {
      // CHANGE API CALL -- REMOVE APT HASH PARAMS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      history.push(`/edit/${'CHANGE_THIS'}`);
      return;
    }
    setActiveItem(null);
    setMode('item');
    resetInfoCard();
  }, [history, mode, resetInfoCard]);

  const handleSelectCustom = useCallback(
    async (selectable: Customizer) => {
      const updatedItemLib = itemLib.map((item) => {
        if (item.id !== selectable.father) return item;
        const newSelectedModel =
          selectable.type === 'model' ? selectable.id : item.selected_model;
        const newSelectedMaterial =
          selectable.type === 'material'
            ? selectable.id
            : item.selected_material;

        return {
          ...item,
          selected_material: newSelectedMaterial,
          selected_model: newSelectedModel,
        };
      });

      if (!activeItem) return;

      const findItem = updatedItemLib.find(
        (item) => item.id === selectable.father,
      );
      if (!findItem) return;

      setInfoCard({
        active: true,
        itemName: selectable.name,
        itemThumb: selectable.thumb,
      });

      setActiveItem(findItem);

      setItemLib(updatedItemLib);
    },
    [activeItem, itemLib],
  );

  const handleModeChange = useCallback(
    (newMode: Modes) => {
      resetInfoCard();
      setMode(newMode);
    },
    [resetInfoCard],
  );

  const handleConfirmConclusion = useCallback(async () => {
    // CHANGE API CALL -- REMOVE APT HASH PARAMS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    await api.post(`apartment/${'change_this'}/${params.selectedRoom}`, {
      finished: true,
      configuration: allSelectedItens,
      roomPreviewUrl: bgImage,
    });

    addToast({
      type: 'success',
      title: 'Cômodo personalizado com sucesso',
      description: 'A personalização de seu cômodo foi salva com sucesso',
    });

    history.push(`/edit`);
  }, [params.selectedRoom, allSelectedItens, bgImage, addToast, history]);

  const handleConclude = useCallback(() => {
    handleModeChange('confirmation');

    const selectedItems = itemLib.map(
      (item): SelectedItem => {
        const itemType = item.name;
        const selectedMaterialData = item.material_list.find(
          (material) => material.id === item.selected_material,
        );
        const selectedModelData = item.model_list.find(
          (model) => model.id === item.selected_model,
        );

        const material =
          !selectedMaterialData || item.material_list.length < 2
            ? undefined
            : selectedMaterialData.name;

        const model =
          !selectedModelData || item.model_list.length < 2
            ? undefined
            : selectedModelData.name;

        return {
          type: itemType,
          model,
          material,
        };
      },
    );

    setAllSelectedItems(selectedItems);
  }, [handleModeChange, itemLib]);

  /// /////////////////////////////// JUST FOR MOCK/////////////////////////
  useEffect((): void => {
    const counterTop = itemLib.find((item) => item.id === 'bancada1');
    const floor = itemLib.find((item) => item.id === 'piso1');
    const wall = itemLib.find((item) => item.id === 'paredes1');
    const faucet = itemLib.find((item) => item.id === 'torneira1');

    if (!counterTop || !floor || !wall || !faucet) return;

    const selectedCounterTop =
      counterTop.selected_material === 'counter_top_mat_1' ? 'Pia-a' : 'Pia-b';

    const selectedFloor =
      floor.selected_material === 'floor_mat_1' ? 'Piso-a' : 'Piso-b';

    const selectedWall =
      wall.selected_material === 'walls_mat_1' ? 'Wall-a' : 'Wall-b';

    const selectedFaucet =
      faucet.selected_model === 'faucet_1' ? 'Tor-a' : 'Tor-b';

    const imageName = `${selectedCounterTop}_${selectedFloor}_${selectedWall}_${selectedFaucet}`;

    if (
      bgImage ===
      `https://s3.amazonaws.com/vizuu.com-shared-media/sample-renders/${imageName}.jpg`
    ) {
      return;
    }

    const newImage = new Image();

    setRenderLoading(true);

    newImage.src = `https://s3.amazonaws.com/vizuu.com-shared-media/sample-renders/${imageName}.jpg`;

    newImage.onload = () => {
      setBgImage(newImage.src);
    };
  }, [bgImage, itemLib]);

  useEffect((): void => {
    setRenderLoading(false);
  }, [bgImage]);
  /// ////////////////////////// JUST FOR MOCK END/////////////////////////

  useEffect(() => {
    // CHANGE API CALL -- REMOVE APT HASH PARAMS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    api
      .get<APIReponse>(`apartment/${'params.aptHash'}/${params.selectedRoom}`)
      .then((response) => {
        setItemLib(response.data.itemLib);
        setRoomName(response.data.name);
        setApartmentCompleted(response.data.status);

        if (response.data.status) {
          addToast({
            type: 'info',
            title: 'Modo Visualização',
            description:
              'Como você já finalizou a personalização de seu apartamento você não poderá mais salvar as alterações dos cômodos',
          });
        }
      });
  }, [addToast, params]);

  useEffect(() => {
    const timer = setTimeout(() => {
      resetInfoCard();
    }, 3500);
    return () => clearTimeout(timer);
  }, [resetInfoCard, infoCard]);

  return (
    <Container>
      <Header currentPage="edit" />
      <Content backgroundimg={bgImage}>
        <EditTabs currentTab={mode === 'confirmation' ? 'confirm' : 'edit'} />
        <SideMenu mode={mode}>
          <button type="button" className="back-button" onClick={handleGoBack}>
            <FiArrowLeft size={32} />
          </button>
          <h1>{roomName}</h1>
          {activeItem ? (
            <ItemConfig>
              <div className="item-tag">
                <h2>{activeItem.name}</h2>
              </div>
              <div className="mode-selector">
                {activeItem.model_list.length > 1 && (
                  <ModeSelector
                    type="button"
                    mode={mode}
                    selectorType="model"
                    themecolor={color}
                    onClick={() => handleModeChange('model')}
                  >
                    Modelo
                  </ModeSelector>
                )}

                {activeItem.material_list.length > 1 && (
                  <ModeSelector
                    type="button"
                    mode={mode}
                    selectorType="material"
                    themecolor={color}
                    onClick={() => handleModeChange('material')}
                  >
                    Material
                  </ModeSelector>
                )}
              </div>

              <SelectablesList>
                {selectables ? (
                  selectables.map((selectable) => (
                    <Selectable
                      backgroundimg={selectable.thumb}
                      themecolor={color}
                      selected={selectable.selected}
                      onClick={() => handleSelectCustom(selectable)}
                      key={selectable.id}
                    >
                      <span>{selectable.name}</span>
                    </Selectable>
                  ))
                ) : (
                  <>
                    <SkeletonTheme color="#ddd" highlightColor="#f2f2f2">
                      <Skeleton width={110} height={110} />
                    </SkeletonTheme>
                    <SkeletonTheme color="#ddd" highlightColor="#f2f2f2">
                      <Skeleton width={110} height={110} />
                    </SkeletonTheme>
                    <SkeletonTheme color="#ddd" highlightColor="#f2f2f2">
                      <Skeleton width={110} height={110} />
                    </SkeletonTheme>
                    <SkeletonTheme color="#ddd" highlightColor="#f2f2f2">
                      <Skeleton width={110} height={110} />
                    </SkeletonTheme>
                  </>
                )}
              </SelectablesList>
            </ItemConfig>
          ) : (
            <>
              <ItemsList>
                {itemLib.map((item) => (
                  <button
                    type="button"
                    onClick={() => handleItemSelection(item.id)}
                    key={item.id}
                  >
                    {/* <FiCodesandbox size={18} /> */}
                    <img src={item.icon} alt={item.name} />
                    {item.name}
                  </button>
                ))}
              </ItemsList>
              {!apartmentCompleted && (
                <Conclusion>
                  <Button onClick={handleConclude}>Finalizar</Button>
                </Conclusion>
              )}
            </>
          )}
        </SideMenu>
        <RenderEffects loading={renderLoading}>
          {renderLoading && (
            <LoadingBar>
              <SkeletonTheme color="#fff" highlightColor={color}>
                <Skeleton style={{ width: '100vw', height: 12 }} />
              </SkeletonTheme>
            </LoadingBar>
          )}
        </RenderEffects>

        <InfoCard active={infoCard.active}>
          <button type="button" onClick={resetInfoCard}>
            <FiXSquare size={18} />
          </button>
          <img src={infoCard.itemThumb} alt={infoCard.itemName} />
          <span>{infoCard.itemName}</span>
        </InfoCard>

        {mode === 'confirmation' && (
          <ConfirmationModal>
            <ConfirmationCard>
              <h1>{roomName}</h1>
              <ConfirmationList>
                {allSelectedItens.map((selectedItem) => (
                  <ConfirmationItem>
                    <h2>{selectedItem.type}</h2>
                    {selectedItem.model && <p>- {selectedItem.model}</p>}
                    {selectedItem.material && <p>- {selectedItem.material}</p>}
                  </ConfirmationItem>
                ))}
              </ConfirmationList>
              <ConfirmationButtons>
                <Button onClick={() => handleModeChange('item')}>Voltar</Button>
                <Button buttoncolor="#19C029" onClick={handleConfirmConclusion}>
                  Confirmar
                </Button>
              </ConfirmationButtons>
            </ConfirmationCard>
          </ConfirmationModal>
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RoomEditor;
