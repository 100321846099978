import styled from 'styled-components';

export const Container = styled.header`
  display: flex;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 8;

  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 26px;

  span {
    margin-left: 4px;

    text-decoration: none;
    text-transform: uppercase;

    font-size: 14px;
    font-weight: 700;
    color: #bbb;

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    background-color: #888;

    padding: 4px 16px 0;

    border-radius: 0 0 8px 8px;

    user-select: none;

    &:first-of-type {
      margin-left: 80px;
    }

    &.selected {
      background-color: #222;
      color: #fff;
    }
  }
`;
