import React from 'react';

import { Container, Content } from './styles';

import { useTheme } from '../../hooks/Theme';

const Header: React.FC = ({ children }) => {
  const { backgroundURL } = useTheme();

  return (
    <Container backgroundimg={backgroundURL}>
      <Content>{children}</Content>
    </Container>
  );
};

export default Header;
