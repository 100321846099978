import styled from 'styled-components';

import { shade } from 'polished';

interface ContentCardProps {
  themecolor: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;

  overflow: hidden;
`;

export const ContentCard = styled.div<ContentCardProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 32px;

  background-color: rgba(255, 255, 255, 0.92);
  border-radius: 8px;

  width: 90%;
  max-width: 600px;

  span {
    font-size: 18px;
    margin-bottom: 4px;
  }

  h1 {
    margin-bottom: 32px;
    width: 100%;
    text-align: center;
  }

  input {
    height: 40px;
    width: 100%;

    margin-bottom: 24px;

    border: 2px solid #eaeaea;
    border-radius: 8px;

    padding: 0 8px;
  }

  textarea {
    width: 100%;
    height: 300px;

    border: 2px solid #eaeaea;
    border-radius: 8px;

    resize: none;

    padding: 8px;

    font-size: 16px;
  }

  button {
    margin-top: 24px;
    align-self: center;

    height: 40px;
    width: 120px;

    background-color: ${(props) => props.themecolor};

    border: 0;
    border-radius: 8px;

    font-weight: bold;
    text-transform: uppercase;
    color: #fff;

    transition: background-color 0.2s;

    &:hover {
      background-color: ${(props) => {
        if (props.themecolor) {
          return shade(0.15, props.themecolor);
        }
        return props.themecolor;
      }};
    }
  }
`;
