import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import BaseContent from '../../components/BaseContent';
import Footer from '../../components/Footer';

import { useTheme } from '../../hooks/Theme';

import { Container, ContentCard, BuildingList, BuildingCard } from './styles';

interface Apartment {
  name: string;
  apt: string;
  block: string;
  address: string;
  city: string;
  limit: string;
  hash: string;
  logoUrl: string;
}

const Dashboard: React.FC = () => {
  const { color } = useTheme();
  const history = useHistory();

  const [apartment, setApartment] = useState<Apartment | undefined>(undefined);

  useEffect(() => {
    api
      .get<Apartment>('user/home')
      .then((response) => setApartment(response.data));
  }, []);

  const handleCardSelection = useCallback(() => {
    history.push(`/edit`);
  }, [history]);

  return (
    <Container>
      <Header currentPage="home" animate />
      <BaseContent>
        <ContentCard>
          <h1>Bem Vindo(a)</h1>
          <BuildingList columns={1}>
            {apartment && (
              <BuildingCard
                themecolor={color}
                key={apartment.hash}
                onClick={() => handleCardSelection()}
              >
                <img src={apartment.logoUrl} alt={apartment.name} />
                <h2>{apartment.name}</h2>
                <span className="apto">
                  Apto: {apartment.apt} {apartment.block}
                </span>
                <address>
                  {apartment.address}
                  <br />
                  {apartment.city}
                </address>
                <span>Data Limite: {apartment.limit}</span>
              </BuildingCard>
            )}
          </BuildingList>
        </ContentCard>
      </BaseContent>

      <Footer animate />
    </Container>
  );
};

export default Dashboard;
