import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface ThemeRequest {
  userHash?: string;
}

interface ThemeContextData {
  companyName: string;
  logoUrl: string;
  smallLogoUrl: string;
  color: string;
  buildingName: string;
  buildingLogoUrl: string;
  backgroundURL: string;
  getCompanyTheme(): Promise<void>;
}

interface ApiResponseData {
  name: string;
  logo: string;
  smallLogo: string;
  themeColor: string;
  background: string;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const ThemeProvider: React.FC = ({ children }) => {
  const [companyName, setCompanyName] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [smallLogoUrl, setSmallLogoUrl] = useState('');
  const [color, setColor] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingLogoUrl, setBuildingLogoUrl] = useState('');
  const [backgroundURL, setBackgroundUrl] = useState('');

  const getCompanyTheme = useCallback(async () => {
    const storedColor = localStorage.getItem('@VizuuB2B:theme_color');
    const storedName = localStorage.getItem('@VizuuB2B:company_name');
    const storedLogo = localStorage.getItem('@VizuuB2B:company_logo');
    const storedLogoS = localStorage.getItem('@VizuuB2B:company_logo_s');
    const storedBackground = localStorage.getItem('@VizuuB2B:theme_background');

    const lastRequest =
      localStorage.getItem('@VizuuB2B:theme_timestamp') || '0';

    const timeDelta = Math.abs(parseInt(lastRequest, 10) - Date.now()) / 36e5;

    setBuildingName('Tayga');
    setBuildingLogoUrl(
      'https://app-gobarber-victor.s3.amazonaws.com/vizuu_test/taiga.png',
    );

    if (
      !storedColor ||
      !storedName ||
      !storedLogo ||
      !storedLogoS ||
      !storedBackground ||
      timeDelta > 0.01
    ) {
      const response = await api.get<ApiResponseData>('theme');

      const companyInfo = response.data;

      setCompanyName(companyInfo.name);
      setLogoUrl(companyInfo.logo);
      setSmallLogoUrl(companyInfo.smallLogo);
      setColor(companyInfo.themeColor);
      setBackgroundUrl(companyInfo.background);

      localStorage.setItem('@VizuuB2B:company_name', companyInfo.name);
      localStorage.setItem('@VizuuB2B:theme_color', companyInfo.themeColor);
      localStorage.setItem('@VizuuB2B:company_logo', companyInfo.logo);
      localStorage.setItem('@VizuuB2B:company_logo_s', companyInfo.smallLogo);
      localStorage.setItem(
        '@VizuuB2B:theme_background',
        companyInfo.background,
      );

      localStorage.setItem('@VizuuB2B:theme_timestamp', Date.now().toString());

      return;
    }

    setCompanyName(storedName);
    setLogoUrl(storedLogo);
    setSmallLogoUrl(storedLogoS);
    setColor(storedColor);
    setBackgroundUrl(storedBackground);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        companyName,
        logoUrl,
        smallLogoUrl,
        color,
        buildingName,
        buildingLogoUrl,
        backgroundURL,
        getCompanyTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used whitin an ThemeProvider');
  }

  return context;
}

export { ThemeProvider, useTheme };
