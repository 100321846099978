import React from 'react';

import { Container } from './styles';

interface EditTabs {
  currentTab: string;
}

const EditTabs: React.FC<EditTabs> = ({ currentTab }) => {
  return (
    <Container>
      <span className={currentTab === 'choose' ? 'selected' : ''}>
        Escolha o Cômodo
      </span>
      <span className={currentTab === 'edit' ? 'selected' : ''}>
        Personalize
      </span>
      <span className={currentTab === 'confirm' ? 'selected' : ''}>
        Confirme
      </span>
    </Container>
  );
};

export default EditTabs;
