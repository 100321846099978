import styled, { keyframes, css } from 'styled-components';

interface ContainerProps {
  animate?: boolean;
}

const footerPop = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 100%);
  }
  to{
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Container = styled.header<ContainerProps>`
  display: flex;

  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: 40px;
  background-color: #222;

  margin-top: -40px;

  img {
    height: 24px;
    margin-right: 8px;
  }

  ${(props) =>
    props.animate
      ? css`
          animation: ${footerPop} 1s ease-out;
        `
      : ''}
`;
