import styled from 'styled-components';

import { shade } from 'polished';

interface ContainerProps {
  bgcolor: string;
}

export const Container = styled.button<ContainerProps>`
  background-color: ${(props) => props.bgcolor};

  border: 0;
  border-radius: 8px;

  font-weight: bold;
  text-transform: uppercase;
  color: #fff;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => {
      if (props.bgcolor) {
        return shade(0.15, props.bgcolor);
      }
      return props.bgcolor;
    }};
  }
`;
