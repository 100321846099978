import styled, { keyframes } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const hint = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  80%{
    opacity: 1;
    visibility: visible;
  }
  99%{
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity:0;
    visibility: 0;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-left: 16px;
  padding-right: 8px;

  background: #fff;
  height: 48px;

  margin-bottom: 8px;

  border-radius: 8px;
  border: 1px solid;

  border-color: #ddd;

  border-color: ${(props) => props.isErrored && '#c53030'};
  border-color: ${(props) => props.isFocused && '#4dbcc3'};

  svg {
    color: #888;

    color: ${(props) => props.isFilled && '#4dbcc3'};
    color: ${(props) => props.isErrored && '#c53030'};
    color: ${(props) => props.isFocused && '#4dbcc3'};
  }

  input {
    height: 100%;
    background: none;
    border: 0;
    &::placeholder {
      color: #999;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset !important;
  }
`;

export const IconElement = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;

export const Error = styled.span`
  width: 160px;
  background: #c53030;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.4s;
  visibility: hidden;

  display: flex;
  justify-content: center;

  position: absolute;

  bottom: calc(100% + 12px);

  left: 50%;
  transform: translateX(-50%);

  color: #fafafa;

  animation: ${hint} 2s ease-in;

  &::before {
    content: '';
    border-style: solid;
    border-color: #c53030 transparent;
    border-width: 6px 6px 0 6px;
    top: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
