import React from 'react';

import { Container } from './styles';

import vizuuLogo from '../../assets/vizuu_logo_white.png';

interface FooterProps {
  animate?: boolean;
}

const Footer: React.FC<FooterProps> = ({ animate }) => {
  return (
    <Container animate={animate}>
      <img src={vizuuLogo} alt="Powered by: Vizuu" />
    </Container>
  );
};

export default Footer;
