import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface User {
  name: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInData {
  email?: string;
  userHash?: string;
  password: string;
}

interface SignInResults {
  validLogin: boolean;
}

interface AuthContextData {
  user: User;
  signIn(data: SignInData): Promise<SignInResults>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@VizuuB2B:token');
    const user = localStorage.getItem('@VizuuB2B:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, userHash, password }): Promise<
    SignInResults
  > => {
    try {
      const response = await api.post('sessions', {
        email,
        userHash,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@VizuuB2B:token', token);
      localStorage.setItem('@VizuuB2B:user', JSON.stringify(user));

      setData({ token, user });
      return { validLogin: true };
    } catch (err) {
      return { validLogin: false };
    }
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@VizuuB2B:token');
    localStorage.removeItem('@VizuuB2B:user');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
