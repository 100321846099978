import styled, { keyframes, css } from 'styled-components';

import { backgroundFade, slideUp } from '../../styles/animationsMaster';

interface PulserProps {
  animate?: boolean;
  position: {
    x: string;
    y: string;
  };
  color: string;
}

interface RoomItemProps {
  selected: boolean;
}

const pulse = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to{
    opacity: 0;
    transform: translate(-50%, -50%) scale(10);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;

  overflow: hidden;
`;

export const ApartmentContent = styled.div`
  width: 100%;
  max-width: 1700px;

  height: 90%;
  max-height: 800px;

  padding: 0 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  animation: ${slideUp} 0.7s ease-out;
`;

export const ApartmentInfoCard = styled.div`
  width: 350px;
  height: 100%;

  border-radius: 8px;

  background: rgba(255, 255, 255, 0.92);

  padding: 32px 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 24px;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      margin-top: 16px;
      height: 40px;
    }
  }
`;

export const ApartmentPlanCard = styled.div`
  flex: 1;
  margin-left: 16px;
  height: 100%;

  border-radius: 8px;

  background: rgba(255, 255, 255, 0.92);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 32px;
`;

export const PlanContainer = styled.div`
  width: 100%;

  position: relative;

  max-height: 100%;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RoomsList = styled.div`
  margin-top: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RoomItem = styled.button<RoomItemProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 4px 8px;

  margin: 4px 0;

  border: 0;

  border-radius: 8px;

  transition: background-color 0.2s;

  background: ${(props) =>
    props.selected
      ? `linear-gradient(
    270deg,
    rgba(255, 144, 0, 0) 10.42%,
    rgba(255, 144, 0, 0.163567) 39.72%,
    rgba(255, 144, 0, 0.5) 100%
  )`
      : `transparent`};

  span {
    text-align: left;

    flex: 1;
    font-size: 18px;
  }

  &:hover {
    background: linear-gradient(
      270deg,
      rgba(255, 144, 0, 0) 10.42%,
      rgba(255, 144, 0, 0.163567) 39.72%,
      rgba(255, 144, 0, 0.5) 100%
    );
  }
`;

export const Pulser = styled.div<PulserProps>`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  position: absolute;
  border-radius: 50%;

  top: ${(props) => props.position.y};
  left: ${(props) => props.position.x};

  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%) scale(1);

  ${(props) =>
    props.animate
      ? css`
          animation: ${pulse} 0.7s infinite ease-out;
        `
      : ''}
`;

export const ConfirmationModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 3;
  padding: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(50, 50, 50, 0.9);

  animation: ${backgroundFade} 0.5s ease-out;
`;

export const ConfirmationCard = styled.div`
  position: relative;

  z-index: 4;

  background-color: rgba(255, 255, 255, 0.92);

  width: 100%;
  max-width: 700px;

  height: 100%;
  max-height: 1000px;

  padding: 32px;

  border-radius: 8px;

  animation: ${slideUp} 0.4s ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ConfirmationList = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-right: 16px;

  margin: 32px 0;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #d4d4d4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
  }
`;

export const RoomConfirmation = styled.div`
  width: 100%;

  padding-bottom: 8px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c4c4c4;

  h3 {
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }
`;

export const RoomConfirmationList = styled.div`
  margin-top: 8px;
`;

export const RoomConfirmationItem = styled.div`
  margin-bottom: 16px;

  h4 {
    font-size: 18px;
    margin-bottom: 2px;
  }

  p {
    margin-left: 16px;
  }
`;

export const ConfirmationButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 40px;
  width: 100%;

  gap: 64px;
`;

export const Disclosure = styled.div`
  padding: 8px;

  background-color: #e4e4e4;

  border: 2px solid #d4d4d4;

  border-radius: 8px;

  h2 {
    display: flex;
    align-items: center;

    font-size: 20px;

    svg {
      margin-right: 8px;
    }
  }

  p {
    margin-top: 8px;
    margin-left: 16px;
  }
`;

export const ConfirmationBox = styled.div`
  display: flex;
  align-items: center;

  padding: 8px;

  background-color: #e4e4e4;

  border: 2px solid #d4d4d4;

  border-radius: 8px;

  margin-top: 8px;

  p {
    margin-left: 8px;
  }
`;
