import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { Container, IconElement, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({ name, icon: Icon, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, error, registerField } = useField(name);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);

    if (inputRef.current?.value) {
      setIsFilled(true);
      setIsErrored(false);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
    if (error) {
      setIsErrored(true);
    }
  }, [error, fieldName, registerField]);

  return (
    <Container isFocused={isFocused} isFilled={isFilled} isErrored={isErrored}>
      <input
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={inputRef}
        {...rest}
      />
      <IconElement>
        {Icon && <Icon size={18} />}
        {isErrored && <Error>{error}</Error>}
      </IconElement>
    </Container>
  );
};

export default Input;
