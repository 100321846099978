import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { FiHome, FiLogOut, FiSettings } from 'react-icons/fi';

import { Container, MenuNav, UserInfo, UserText, UserDropMenu } from './styles';

import { useTheme } from '../../hooks/Theme';
import { useAuth } from '../../hooks/Auth';

import api from '../../services/api';

interface HeaderProps {
  currentPage: string;
  animate?: boolean;
}

const Header: React.FC<HeaderProps> = ({ currentPage, animate }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const { smallLogoUrl, companyName, getCompanyTheme, color } = useTheme();
  const { signOut } = useAuth();

  useEffect(() => {
    if (companyName.length) return;
    getCompanyTheme();
  }, [companyName, getCompanyTheme]);

  const handleReset = useCallback(async () => {
    await api.post('reset', {});
    signOut();
  }, [signOut]);

  return (
    <Container animate={animate}>
      <img src={smallLogoUrl} alt={companyName} />
      <MenuNav>
        <Link
          to="/home"
          style={
            currentPage === 'home'
              ? { borderBottom: `2px solid ${color}` }
              : { borderBottom: `2px solid #222` }
          }
        >
          Home
        </Link>

        <Link
          to="/edit"
          style={
            currentPage === 'edit'
              ? { borderBottom: `2px solid ${color}` }
              : { borderBottom: `2px solid #222` }
          }
        >
          Personalize
        </Link>

        <Link
          to="/contact"
          style={
            currentPage === 'contact'
              ? { borderBottom: `2px solid ${color}` }
              : { borderBottom: `2px solid #222` }
          }
        >
          Fale Conosco
        </Link>
      </MenuNav>
      <UserInfo type="button" onClick={() => setOpenMenu(!openMenu)}>
        {/* ********************CHANGE TO DINAMIC VALUE************************* */}
        <UserText>
          <span>Tayga</span>
          <span>Apto: 123 Gold</span>
        </UserText>
        <FiHome size={32} />
      </UserInfo>
      {openMenu && (
        <UserDropMenu>
          <button type="button" onClick={signOut}>
            Logout <FiLogOut size={14} />
          </button>
          <button type="button" onClick={handleReset}>
            Reset App <FiSettings size={14} />
          </button>
        </UserDropMenu>
      )}
    </Container>
  );
};

export default Header;
