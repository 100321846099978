interface InnerError {
  path: string;
  message: string;
}

class AuthError {
  public readonly inner: InnerError[];

  constructor(errorList: InnerError[]) {
    this.inner = errorList;
  }
}

export default AuthError;
