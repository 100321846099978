import styled from 'styled-components';

interface ContainerProps {
  backgroundimg: string;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: -80px;

  background-color: #333;
  background-image: url(${(props) => props.backgroundimg});

  background-size: cover;
  background-position-x: 0;
  background-position-y: bottom;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 80px;
  margin-bottom: 40px;

  position: relative;
`;
