import styled from 'styled-components';

import { slideUp } from '../../styles/animationsMaster';

interface BuildingListProps {
  columns: number;
}

interface BuildingCardProps {
  themecolor: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;

  overflow: hidden;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 64px;

  min-height: 400px;

  background-color: rgba(255, 255, 255, 0.92);
  border-radius: 8px;

  animation: ${slideUp} 0.8s ease-out;
`;

export const BuildingList = styled.div<BuildingListProps>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: 32px;

  margin-top: 32px;
`;

export const BuildingCard = styled.button<BuildingCardProps>`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  padding: 16px;
  border-radius: 8px;

  border: 0;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  border: 1px solid #eaeaea;

  img {
    width: 200px;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
  }

  span.apto {
    font-weight: 700;
  }

  address {
    margin: 32px 0;
    font-style: normal;
    text-align: left;
  }

  a {
    margin-top: 8px;
    text-decoration: none;
    padding: 4px 16px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    background-color: ${(props) => props.themecolor};
  }
`;
