import styled from 'styled-components';

import { backgroundFade, slideUp } from '../../styles/animationsMaster';

type Modes = 'item' | 'model' | 'material' | 'confirmation';

interface ContentProps {
  backgroundimg: string;
}

interface SelectableProps {
  themecolor: string;
  backgroundimg: string;
  selected?: boolean;
}

interface SideMenuProps {
  mode: Modes;
}

interface ModeSelectorProps {
  mode: Modes;
  selectorType: Modes;
  themecolor: string;
}

interface InfoCardProps {
  active: boolean;
}

interface RenderEffectsProps {
  loading?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div<ContentProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  margin-bottom: 40px;

  position: relative;

  background-color: #333;
  background-image: url(${(props) => props.backgroundimg});

  background-size: cover;
  background-position-x: center;
  background-position-y: center;

  overflow: hidden;
`;

export const RenderEffects = styled.div<RenderEffectsProps>`
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: all 0.5s;
  backdrop-filter: ${(props) =>
    props.loading
      ? 'saturate(80%) brightness(98%) blur(2px)'
      : 'saturate(100%) brightness(100%) blur(0px)'};
`;

export const SideMenu = styled.div<SideMenuProps>`
  width: ${(props) =>
    props.mode === 'item' || props.mode === 'confirmation' ? '240px' : '350px'};
  height: 100%;

  background: rgba(255, 255, 255, 0.92);

  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h1 {
    font-size: 24px;
    margin: 16px 0;
  }

  button.back-button {
    border: 0;
    background-color: transparent;
    z-index: ${(props) => (props.mode === 'confirmation' ? '2' : '10')};
  }

  transition: width 200ms;
`;

export const ItemsList = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  button {
    display: flex;
    align-items: center;

    border: 0;
    background: transparent;
    width: 100%;

    text-align: left;
    font-size: 20px;

    margin: 2px -16px;

    padding: 8px 0 8px 16px;

    border-radius: 0 8px 8px 0;

    img {
      margin-right: 8px;
      width: 18px;
    }

    &:hover {
      background: linear-gradient(
        270deg,
        rgba(255, 144, 0, 0) 10.42%,
        rgba(255, 144, 0, 0.163567) 39.72%,
        rgba(255, 144, 0, 0.5) 100%
      );
    }
  }
`;

export const Conclusion = styled.div`
  margin-top: auto;
  width: 100%;
  margin-bottom: 8px;

  button {
    height: 40px;
    width: 100%;
  }
`;

export const ItemConfig = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .item-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      border: 0;
      background-color: transparent;
    }

    h2 {
      margin-left: 8px;
      font-weight: 400;
      font-size: 22px;
      margin-bottom: 2px;
    }
  }

  .mode-selector {
    width: 100%;
    display: flex;

    margin: 16px 0;

    button {
      flex: 1;
      padding: 4px;

      border: 0;
      border-radius: 8px;
      font-weight: 500;

      & + button {
        margin-left: 16px;
      }
    }
  }
`;

export const SelectablesList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const Selectable = styled.button<SelectableProps>`
  width: 100%;
  padding-top: 100%;
  background-color: #f2f2f2;
  background: url(${(props) => props.backgroundimg});

  background-size: cover;
  background-position: center;

  border-radius: 8px;

  border: 0;

  box-shadow: ${(props) =>
    props.selected ? `inset 0 0 0 4px ${props.themecolor}` : `0`};

  position: relative;

  span {
    position: absolute;
    top: -24px;
    left: 80%;

    z-index: 10;

    width: 170px;

    padding: 8px;
    border-radius: 8px;

    background-color: rgba(255, 255, 255, 0.95);
    border: 2px solid #d4d4d4;
    opacity: 0;

    pointer-events: none;
    user-select: none;

    transition: all 0.25s;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`;

export const ModeSelector = styled.button<ModeSelectorProps>`
  background-color: ${(props) =>
    props.mode === props.selectorType ? props.themecolor : '#d4d4d4'};
  color: ${(props) => (props.mode === props.selectorType ? '#fff' : '#333')};
`;

export const InfoCard = styled.div<InfoCardProps>`
  z-index: 10;

  position: absolute;
  bottom: 16px;
  right: 0;

  width: 440px;

  background-color: rgba(255, 255, 255, 0.92);

  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding: 24px;

  border-radius: 8px 0 0 8px;

  button {
    position: absolute;
    left: 5px;
    top: 5px;

    border: 0;
    background-color: transparent;
  }

  span {
    font-size: 20px;
    font-weight: 500;

    margin-left: 16px;
    flex: 1;
  }

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;

    border: 2px solid #d2d2d2;
  }

  transition: all 0.5s;

  opacity: ${(props) => (props.active ? '1' : '0')};
  transform: ${(props) =>
    props.active ? 'translate(0%,0%)' : 'translate(110%,0%)'};
`;

export const ConfirmationModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 3;
  padding: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(50, 50, 50, 0.9);

  animation: ${backgroundFade} 0.5s ease-out;
`;

export const ConfirmationCard = styled.div`
  position: relative;

  z-index: 4;

  background-color: rgba(255, 255, 255, 0.92);

  width: 100%;
  max-width: 550px;

  height: 90%;
  max-height: 800px;

  padding: 32px;

  border-radius: 8px;

  animation: ${slideUp} 0.4s ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    text-transform: uppercase;
  }
`;

export const ConfirmationList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 16px;
`;

export const ConfirmationItem = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  margin: 0px -16px;
  padding: 16px;

  border-top: 1px solid #d4d4d4;

  h2 {
    font-size: 22px;
    font-weight: 500;
  }

  p {
    margin-top: 4px;
    margin-left: 16px;

    font-size: 18px;
  }

  &:hover {
    background: linear-gradient(
      270deg,
      rgba(25, 192, 41, 0) 10.42%,
      rgba(25, 192, 41, 0.163567) 39.72%,
      rgba(25, 192, 41, 0.5) 100%
    );
  }
`;

export const ConfirmationButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;

  width: 100%;
  height: 40px;

  button {
    width: 100%;
    height: 100%;
  }
`;

export const LoadingBar = styled.div`
  position: absolute;

  bottom: 0;
  display: flex;
  height: 8px;
  width: 100%;
  z-index: 2;
`;
