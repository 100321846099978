import styled, { keyframes, css } from 'styled-components';

interface ContainerProps {
  animate?: boolean;
}

const slideFromTop = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -50%);
  }
  to{
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const menuPop = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to{
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Container = styled.header<ContainerProps>`
  display: flex;

  z-index: 98;

  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: #222;

  position: relative;

  img {
    height: 80px;
  }

  ${(props) =>
    props.animate
      ? css`
          animation: ${menuPop} 1s ease-out;
        `
      : ''}
`;

export const MenuNav = styled.nav`
  width: 70%;

  max-width: 550px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    padding: 2px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const UserInfo = styled.button`
  font-size: 18;
  font-weight: 500;
  margin-right: 32px;
  display: flex;
  align-items: center;
  color: #fff;

  border: 0;
  background-color: transparent;

  padding: 16px 0;
`;

export const UserDropMenu = styled.div`
  width: 140px;

  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background-color: #fff;

  position: absolute;

  right: 30px;
  top: 60px;

  z-index: 99;

  border-radius: 4px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  animation: ${slideFromTop} 0.2s ease-out;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    text-align: right;
    width: 100%;

    font-weight: 500;

    border: 0;

    background-color: transparent;

    svg {
      margin-left: 8px;
    }

    & + button {
      margin-top: 12px;
    }
  }
`;

export const UserText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  span {
    font-size: 14px;
    margin-right: 8px;
  }
`;
