import styled from 'styled-components';

interface ContainerProps {
  background: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;

  background-color: #333;
  background-image: url(${(props) => props.background});

  background-size: cover;
  background-position-x: 0;
  background-position-y: bottom;
`;

export const LoginCard = styled.div`
  width: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  margin-left: 120px;
  padding: 48px 64px 56px;

  border-radius: 8px;

  background: rgba(255, 255, 255, 0.92);

  img {
    width: 160px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 40px;

    z-index: 50;

    h1 {
      font-size: 30px;
      font-weight: bold;
      margin: 32px auto;
    }

    a {
      margin-top: 24px;
      color: #444;
      text-decoration: none;
      font-weight: 700;
    }

    button {
      width: 100%;
      height: 48px;

      font-size: 16px;
    }
  }
`;

export const VizuuLogo = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  padding: 4px 8px;

  font-size: 14px;

  img {
    margin-bottom: 2px;
    height: 32px;
    width: auto;
  }
`;
