interface Errors {
  [key: string]: string;
}

interface ValidationError {
  inner: {
    path: string;
    message: string;
  }[];
}

export default function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};

  err.inner.forEach((error) => {
    validationErrors[error.path] = error.message;
  });
  return validationErrors;
}
