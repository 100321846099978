import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Checkbox from 'rc-checkbox';

import { FiCheckSquare, FiSquare, FiAlertTriangle } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import BaseContent from '../../components/BaseContent';
import EditTabs from '../../components/EditTabs';
import Footer from '../../components/Footer';

import { useTheme } from '../../hooks/Theme';
import { useToast } from '../../hooks/toast';

import {
  Container,
  ApartmentContent,
  ApartmentInfoCard,
  ApartmentPlanCard,
  PlanContainer,
  RoomsList,
  RoomItem,
  Pulser,
  ConfirmationModal,
  ConfirmationCard,
  ConfirmationList,
  RoomConfirmation,
  RoomConfirmationList,
  RoomConfirmationItem,
  ConfirmationButtons,
  Disclosure,
  ConfirmationBox,
} from './styles';
import Button from '../../components/Button';

interface SelectedItem {
  type: string;
  model: string | undefined;
  material: string | undefined;
}

interface RoomInfo {
  name: string;
  finished: boolean;
  coordinates: {
    x: string;
    y: string;
  };
  roomPreviewUrl: string;
  configuration: SelectedItem[];
}

interface PathParams {
  aptHash: string;
}

interface ApartmentFullData {
  buildingName: string;
  apt: string;
  aptHash: string;
  block: string;
  aptFloorPlan: string;
  completed: boolean;
  rooms: RoomInfo[];
}

const RoomSelector: React.FC = () => {
  const [apartmentInfo, setApartmentInfo] = useState<ApartmentFullData>({
    buildingName: '',
    apt: '',
    aptHash: '',
    block: '',
    aptFloorPlan: '',
    completed: false,
    rooms: [],
  });

  const [confirmationMode, setConfirmationMode] = useState<boolean>(false);
  const [apartmentConfirmed, setApartmentConfirmed] = useState<boolean>(false);

  const [selectedRoom, setSelectedRoom] = useState<RoomInfo | null>(null);
  const [hoverRoom, setHoverRoom] = useState<RoomInfo | null>(null);

  const history = useHistory();
  const { color } = useTheme();
  const { addToast } = useToast();

  useEffect(() => {
    // CHANGE API CALL -- REMOVE APT HASH PARAMS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    api
      .get<ApartmentFullData>(`apartment/${'CHANGE_THIS'}`)
      .then((response) => setApartmentInfo(response.data));
  }, []);

  const editRoomPath = useMemo(() => {
    return selectedRoom
      ? selectedRoom.name.toLocaleLowerCase().replace(/ /g, '-')
      : '';
  }, [selectedRoom]);

  const currentPulser = useMemo(() => {
    return hoverRoom || selectedRoom;
  }, [hoverRoom, selectedRoom]);

  const isFinished = useMemo(() => {
    return apartmentInfo.rooms.every((apt) => apt.finished === true);
  }, [apartmentInfo.rooms]);

  const handleRoomSelect = useCallback(
    (roomName: string) => {
      if (selectedRoom?.name === roomName) {
        setSelectedRoom(null);
        return;
      }
      const roomData = apartmentInfo.rooms.find(
        (room) => roomName === room.name,
      );

      roomData ? setSelectedRoom(roomData) : setSelectedRoom(null);
    },
    [apartmentInfo.rooms, selectedRoom],
  );

  const handleRoomHover = useCallback(
    (roomName: string) => {
      const roomData = apartmentInfo.rooms.find(
        (room) => roomName === room.name,
      );

      roomData ? setHoverRoom(roomData) : setHoverRoom(null);
    },
    [apartmentInfo.rooms],
  );

  const handleEditRoom = useCallback(() => {
    if (!selectedRoom) {
      addToast({
        type: 'error',
        title: 'Selecione um cômodo antes de continuar',
      });
      return;
    }
    history.push(`/edit/${editRoomPath}`);
  }, [addToast, editRoomPath, history, selectedRoom]);

  const handleConfirmation = useCallback((e) => {
    setApartmentConfirmed(e.target.checked);
  }, []);

  const handleRequestConfirmation = useCallback(() => {
    if (!isFinished) return;

    setConfirmationMode(true);
  }, [isFinished]);

  const handleApartmentFinalConfirmation = useCallback(async () => {
    if (!apartmentConfirmed) return;

    // CHANGE API CALL -- REMOVE APT HASH PARAMS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    const response = await api.post<ApartmentFullData>(
      `apartment/${'CHANGE_THIS'}`,
      {
        confirmation: true,
      },
    );

    setApartmentInfo(response.data);

    setConfirmationMode(false);

    setApartmentConfirmed(false);

    addToast({
      type: 'success',
      title: 'Personalização de Apartamento Concluída',
      description:
        'As configurações de seu apartamento foram salvas e enviadas para a Bild',
    });

    history.push('/home');
  }, [addToast, apartmentConfirmed, history]);

  return (
    <Container>
      <Header currentPage="edit" />
      <BaseContent>
        <EditTabs currentTab="choose" />
        <ApartmentContent>
          <ApartmentInfoCard>
            <h1>Escolha o cômodo para personalizar</h1>
            <RoomsList>
              {apartmentInfo.rooms.map((room) => (
                <RoomItem
                  selected={room.name === selectedRoom?.name}
                  type="button"
                  onClick={() => handleRoomSelect(room.name)}
                  onMouseOver={() => handleRoomHover(room.name)}
                  onMouseOut={() => setHoverRoom(null)}
                  key={room.name}
                >
                  <span>{room.name}</span>
                  {room.finished ? (
                    <FiCheckSquare color={color} size={24} />
                  ) : (
                    <FiSquare size={24} />
                  )}
                </RoomItem>
              ))}
            </RoomsList>
            <div className="buttons">
              <Button
                buttoncolor={selectedRoom ? color : '#d5d5d5'}
                onClick={() => handleEditRoom()}
              >
                {apartmentInfo.completed ? 'Visualizar' : 'Personalizar'}
              </Button>

              <Button
                buttoncolor={isFinished ? '#19C029' : '#d5d5d5'}
                onClick={handleRequestConfirmation}
              >
                {apartmentInfo.completed ? 'Ver Configuração' : 'Finalizar'}
              </Button>
            </div>
          </ApartmentInfoCard>

          <ApartmentPlanCard>
            <PlanContainer>
              <img
                src={apartmentInfo.aptFloorPlan}
                alt={`Planta do apartamento ${apartmentInfo.apt}`}
              />
              {currentPulser && (
                <>
                  <Pulser
                    animate
                    position={{
                      x: currentPulser.coordinates.x,
                      y: currentPulser.coordinates.y,
                    }}
                    color={color}
                  />
                  <Pulser
                    position={{
                      x: currentPulser.coordinates.x,
                      y: currentPulser.coordinates.y,
                    }}
                    color={color}
                  />
                </>
              )}
            </PlanContainer>
          </ApartmentPlanCard>
        </ApartmentContent>

        {confirmationMode && (
          <ConfirmationModal>
            <ConfirmationCard>
              <h2>Edifício Tayga</h2>
              <h2>apartamento 123</h2>
              <ConfirmationList>
                {apartmentInfo.rooms.map((room) => (
                  <RoomConfirmation>
                    <h3>{room.name}</h3>
                    <img
                      src={room.roomPreviewUrl}
                      alt={`configured ${room.name}`}
                    />
                    <RoomConfirmationList>
                      {room.configuration.map((selectedItem) => (
                        <RoomConfirmationItem>
                          <h4>{selectedItem.type}</h4>
                          {selectedItem.model && <p>- {selectedItem.model}</p>}
                          {selectedItem.material && (
                            <p>- {selectedItem.material}</p>
                          )}
                        </RoomConfirmationItem>
                      ))}
                    </RoomConfirmationList>
                  </RoomConfirmation>
                ))}
                {!apartmentInfo.completed && (
                  <>
                    <Disclosure>
                      <h2>
                        <FiAlertTriangle color={color} size={20} />
                        Atenção:
                      </h2>
                      <p>
                        Verifique se todos os cômodos contém as personalizações
                        solicitadas e as imagens condizem com os itens
                        escolhidos! Pois após a confirmação da planta, não será
                        mais possível alterar as configurações do apartamento.
                      </p>
                    </Disclosure>
                    <ConfirmationBox>
                      <Checkbox onChange={handleConfirmation} />
                      <p>
                        Confirmo que veriquei todos os itens selecionados e
                        concordo que não poderei mais realizar alterações na
                        planta do meu apartamento.
                      </p>
                    </ConfirmationBox>
                  </>
                )}
              </ConfirmationList>
              <ConfirmationButtons>
                <Button onClick={() => setConfirmationMode(false)}>
                  Voltar
                </Button>
                <Button
                  buttoncolor={apartmentConfirmed ? '#19C029' : '#d5d5d5'}
                  onClick={handleApartmentFinalConfirmation}
                >
                  Confirmar
                </Button>
              </ConfirmationButtons>
            </ConfirmationCard>
          </ConfirmationModal>
        )}
      </BaseContent>

      <Footer />
    </Container>
  );
};

export default RoomSelector;
