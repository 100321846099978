import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import BaseContent from '../../components/BaseContent';
import Footer from '../../components/Footer';

import { useTheme } from '../../hooks/Theme';
import { useToast } from '../../hooks/toast';

import { Container, ContentCard } from './styles';

const Contact: React.FC = () => {
  const { color } = useTheme();
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(() => {
    addToast({
      type: 'success',
      title: 'Mensagem enviada com sucesso',
      description:
        'Sua mensagem foi enviada com sucesso para nossa equipe, logo retornaremos o contato',
    });
    history.goBack();
  }, [addToast, history]);

  return (
    <Container>
      <Header currentPage="contact" />
      <BaseContent>
        <ContentCard themecolor={color}>
          <h1>Envie-nos uma mensagem</h1>
          <span>Assunto</span>
          <input type="text" />
          <span>Mensagem</span>
          <textarea />
          <button type="button" onClick={handleSubmit}>
            Enviar
          </button>
        </ContentCard>
      </BaseContent>

      <Footer />
    </Container>
  );
};

export default Contact;
