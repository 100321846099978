import React, { ButtonHTMLAttributes, useMemo } from 'react';

import { Container } from './styles';

import { useTheme } from '../../hooks/Theme';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttoncolor?: string;
}

const Button: React.FC<ButtonProps> = ({ children, buttoncolor, ...rest }) => {
  const { color } = useTheme();

  const selectedColor = useMemo(() => {
    return buttoncolor || color;
  }, [buttoncolor, color]);

  return (
    <Container bgcolor={selectedColor} type="button" {...rest}>
      {children}
    </Container>
  );
};

export default Button;
